<template>
  <settings-form v-model="obSettings">
    <v-divider />
    <v-col cols="12">
      <sheet>
        <payment-methods v-model="obCompany" />
      </sheet>
    </v-col>
  </settings-form>
</template>

<script lang="ts">
import type { CompanySettingsData } from "@planetadeleste/vue-mc-gw";
import { Company, CompanySettings } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, VModel } from "vue-property-decorator";
import SettingsForm from "@/modules/companies/components/SettingsForm.vue";
import PaymentMethods from "@/modules/companies/components/tabs/PaymentMethods.vue";

@Component({
  components: { SettingsForm, PaymentMethods },
})
export default class CompanyAdvancedSettings extends Vue {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;

  mounted() {
    this.$nextTick(() => {
      const obSettings = this.obCompany.get("settings");
      if (this.$_.isEmpty(obSettings) || this.$_.isArray(obSettings)) {
        this.obCompany.set("settings", new CompanySettings());
      }
    });
  }

  get obSettings() {
    const obSettings = this.obCompany.get("settings");
    return this.$_.isEmpty(obSettings) || !this.$_.isObject(obSettings)
      ? new CompanySettings()
      : obSettings instanceof CompanySettings
      ? obSettings
      : new CompanySettings(obSettings);
  }

  set obSettings(obVal: CompanySettingsData | Record<string, any>) {
    this.obCompany.set("settings", obVal);
  }
}
</script>
